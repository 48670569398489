"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formatters_1 = require("./formatters");
var fold = function (data) {
    var parts = data.match(/[\s\S]{1,75}/g) || [];
    return parts.join('\r\n ');
};
exports.compile = function (data) {
    var elements = [];
    var head = 'BEGIN:VCARD\r\nVERSION:3.0';
    elements.push(head);
    if (data.name && data.hasOwnProperty('name')) {
        elements.push(formatters_1.formatters.name(data.name));
        elements.push(formatters_1.formatters.formattedName(data.name));
    }
    if (data.adr && data.hasOwnProperty('adr')) {
        var address = data.adr.map(function (address) { return formatters_1.formatters.adr(address); });
        elements.push.apply(elements, address);
    }
    if (data.bday && data.hasOwnProperty('bday')) {
        elements.push(formatters_1.formatters.bday(data.bday));
    }
    if (data.categories && data.hasOwnProperty('categories')) {
        elements.push(formatters_1.formatters.categories(data.categories));
    }
    if (data.email && data.hasOwnProperty('email')) {
        var email = data.email.map(function (emailAddress) { return formatters_1.formatters.email(emailAddress); });
        elements.push.apply(elements, email);
    }
    if (data.nickname && data.hasOwnProperty('nickname')) {
        elements.push(formatters_1.formatters.nickname(data.nickname));
    }
    if (data.note && data.hasOwnProperty('note')) {
        elements.push(formatters_1.formatters.note(data.note));
    }
    if (data.org && data.hasOwnProperty('org')) {
        elements.push(formatters_1.formatters.org(data.org));
    }
    if (data.title && data.hasOwnProperty('title')) {
        elements.push(formatters_1.formatters.title(data.title));
    }
    if (data.photo && data.hasOwnProperty('photo')) {
        elements.push(formatters_1.formatters.photo(data.photo));
    }
    if (data.tel && data.hasOwnProperty('tel')) {
        var tel = data.tel.map(function (telephone) { return formatters_1.formatters.tel(telephone); });
        elements.push.apply(elements, tel);
    }
    if (data.url && data.hasOwnProperty('url')) {
        var url = data.url.map(function (u, index) { return formatters_1.formatters.url(u, index + 1); });
        elements.push.apply(elements, url);
    }
    if (data.im && data.hasOwnProperty('im')) {
        var im = data.im.map(function (imObject) { return formatters_1.formatters.im(imObject); }).filter(function (x) { return x; });
        elements.push.apply(elements, im);
    }
    elements.push('END:VCARD');
    var newLine = '\r\n';
    var folded = elements.map(fold);
    var joined = folded.join(newLine);
    return joined;
};
