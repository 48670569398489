"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var escape = function (str) {
    if (!(typeof str === 'string')) {
        return '';
    }
    return str
        .replace(/\n/g, '\\n')
        .replace(/,/g, '\\,')
        .replace(/;/g, '\\;');
};
var escapeThenJoin = function (array, joinChar) { return array.map(function (d) { return escape(d); }).join(joinChar); };
var imLookup = {
    yahoo: 'X-YAHOO:',
    google: 'X-GTALK:',
    aim: 'X-AIM:',
    skype: 'X-SKYPE:',
    qq: 'X-QQ:',
    msn: 'X-MSN:',
    icq: 'X-ICQ:',
    jabber: 'X-JABBER:',
};
exports.formatters = {
    name: function (data) {
        var base = 'N:';
        var last = (data.last) ? escape(data.last) : '';
        var first = (data.first) ? escape(data.first) : '';
        var additional = (data.additional) ? escapeThenJoin(data.additional, ' ') : '';
        var prefix = (data.prefix) ? escape(data.prefix) : '';
        var suffix = (data.suffix) ? escapeThenJoin(data.suffix, ',') : '';
        var n = [last, first, additional, prefix, suffix].join(';');
        return base + n;
    },
    formattedName: function (data) {
        var base = 'FN:';
        var prefix = (data.prefix) ? escape(data.prefix) : '';
        var first = (data.first) ? escape(data.first) : '';
        var additional = (data.additional) ? escapeThenJoin(data.additional, ' ') : '';
        var last = (data.last) ? escape(data.last) : '';
        var suffix = (data.suffix) ? '\\, ' + escapeThenJoin(data.suffix, '\\, ') : '';
        var items = [prefix, first, additional, last].filter(function (d) { return d !== ''; });
        return base + items.join(' ') + suffix;
    },
    adr: function (data) {
        var base = 'ADR';
        var type = (data.type) ? data.type : '';
        type = 'TYPE=' + type + ':';
        var country = (data.country) ? data.country : '';
        var address = [base, type, '', escape(data.street), escape(data.city), escape(data.state), escape(data.zip), escape(country)].join(';');
        return address;
    },
    bday: function (data) {
        // for now, assume that date is coming in formatted yyyy-mm-dd
        var base = 'BDAY:';
        return base + data;
    },
    categories: function (data) {
        var base = 'CATEGORIES:';
        var categories = escapeThenJoin(data, ',');
        return base + categories;
    },
    email: function (data) {
        var base = 'EMAIL;';
        var type = (data.type) ? 'TYPE=' + escape(data.type) : 'TYPE=';
        if (data.preferred) {
            type += ',pref';
        }
        type += ':';
        var address = escape(data.address);
        return base + type + address;
    },
    nickname: function (data) {
        var base = 'NICKNAME:';
        var names = escapeThenJoin(data, ',');
        return base + names;
    },
    note: function (data) {
        var base = 'NOTE:';
        var note = escape(data);
        return base + note;
    },
    org: function (data) {
        var base = 'ORG:';
        var company = (data.company) ? escape(data.company) : '';
        var suborg1 = (data.suborg1) ? escape(data.suborg1) : '';
        var suborg2 = (data.suborg2) ? escape(data.suborg2) : '';
        return base + [company, suborg1, suborg2].join(';');
    },
    title: function (data) {
        var base = 'TITLE:';
        return base + escape(data);
    },
    photo: function (data) {
        var base = 'PHOTO;';
        var meta;
        if (data.type === 'uri') {
            meta = 'VALUE=uri:';
        }
        else {
            meta = 'ENCODING=b;TYPE=' + data.ext + ':';
        }
        return base + meta + data.photo;
    },
    tel: function (data) {
        var base = 'TEL;';
        var type = (data.type) ? escape(data.type) : '';
        type = 'TYPE=' + type;
        if (data.pref) {
            type += ',pref:';
        }
        else {
            type += ':';
        }
        return base + type + escape(data.number);
    },
    url: function (data, i) {
        var item = 'item' + i;
        var url = item + '.URL:' + data.url;
        var label = item + '.X-ABLabel:';
        if (data.type === 'homepage') {
            label += '_$!<HomePage>!$_';
        }
        else {
            label += escape(data.type);
        }
        return url + '\r\n' + label;
    },
    im: function (data) {
        if (imLookup.hasOwnProperty(data.type)) {
            return imLookup[data.type] + escape(data.name);
        }
        else {
            return false;
        }
    },
};
